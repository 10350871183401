<template>
  <v-window v-model="step" class="elevation-1">
    <!-- first step -->
    <v-window-item :value="1">
      <v-card class="pa-6" color="foreground">
        <v-card-title class="px-0 pt-0">
          <v-btn class="rounded-lg mr-3" icon @click="navigation(`/checkout`)">
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>

          Dados Pessoais
        </v-card-title>

        <!-- personal data -->
        <PersonalData />

        <h1 class="mb-4 text-h6">Informe seu Endereço</h1>

        <!-- awaiting -->
        <v-list-item v-if="awaitingMethods.includes('BOLETO')" class="accent">
          <v-list-item-title class="text-center secondary_text--text">
            Transação pendente,
            <a @click="continueTransaction()">clique para continuar</a>
          </v-list-item-title>
        </v-list-item>

        <!-- form -->
        <v-form
          v-else-if="!lockedMethods['BOLETO']"
          v-model="valid"
          ref="form"
          autocomplete="off"
        >
          <v-text-field
            v-model="form.cep"
            v-mask="'#####-###'"
            label="CEP"
            background-color="accent"
            :rules="rule"
            autofocus
            outlined
          >
            <template v-slot:append>
              <v-icon color="secondary"> mdi-numeric </v-icon>
            </template>
          </v-text-field>

          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="form.uf"
                v-mask="'AA'"
                label="Estado"
                background-color="accent"
                outlined
                readonly
              >
                <template v-slot:append>
                  <v-icon color="secondary"> mdi-map-outline </v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="form.city"
                label="Cidade"
                background-color="accent"
                :rules="rule"
                outlined
                readonly
              >
                <template v-slot:append>
                  <v-icon color="secondary"> mdi-home-city-outline </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="form.neighboorhood"
            label="Bairro"
            background-color="accent"
            :rules="rule"
            outlined
          >
            <template v-slot:append>
              <v-icon color="secondary"> mdi-home-group </v-icon>
            </template>
          </v-text-field>

          <v-row>
            <v-col cols="7">
              <v-text-field
                v-model="form.address"
                label="Rua"
                background-color="accent"
                :rules="rule"
                outlined
              >
                <template v-slot:append>
                  <v-icon color="secondary">
                    mdi-map-markernumb-outline
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="5">
              <v-text-field
                v-model="form.number"
                v-mask="'######'"
                label="Número"
                background-color="accent"
                :rules="rule"
                outlined
              >
                <template v-slot:append>
                  <v-icon color="secondary"> mdi-counter </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-text-field
            v-model="form.complement"
            label="Complemento"
            background-color="accent"
            outlined
          >
            <template v-slot:append>
              <v-icon color="secondary"> mdi-dots-horizontal </v-icon>
            </template>
          </v-text-field>

          <v-btn color="primary" block large @click="submit()">
            Continuar Pagamento
          </v-btn>
        </v-form>

        <!-- unavaiable -->
        <v-list-item v-else class="accent">
          <v-list-item-title class="text-center secondary_text--text">
            Serviço indisponível para boleto bancário
          </v-list-item-title>
        </v-list-item>
      </v-card>
    </v-window-item>

    <!-- second step -->
    <v-window-item :value="2">
      <v-card class="pa-6" color="foreground">
        <v-card-title class="px-0 pt-0">
          <v-btn
            v-if="!transactionPerformed"
            class="rounded-lg mr-3"
            icon
            @click="navigation(`/checkout`)"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
          
          Boleto Bancário
        </v-card-title>

        <v-card-subtitle class="text-body-1 px-0">
          Efetue o
          <span class="secondary--text">pagamento</span> do seu boleto, o banco
          pode levar de 24 a 72 horas para
          <span class="secondary--text">compensar</span> seu pagamento.
        </v-card-subtitle>

        <!-- bank slip -->
        <section class="mb-8 mt-6">
          <v-card
            class="rounded-lg mb-3"
            color="accent"
            flat
            @click="openBankSlip()"
          >
            <v-row class="py-4 px-4 align-center justify-center" no-gutters>
              <v-icon color="primary" class="mr-3">mdi-barcode-scan</v-icon>
              <span class="text-button primary--text"> Visualizar Boleto </span>
            </v-row>
          </v-card>

          <input
            v-model="transaction.boletoCode"
            id="code"
            class="
              centered-input
              primary_text--text
              font-weight-regular
              text-caption
              accent
              pa-3
              mb-4
            "
            readonly
            @focus="copyCode()"
          />

          <div align="center">
            <a
              class="secondary--text font-weight-bold text-caption"
              @click="copyCode()"
            >
              <v-icon color="secondary" small left> mdi-content-copy </v-icon>

              COPIAR CÓDIGO
            </a>
          </div>
        </section>

        <!-- receipt -->
        <v-card class="rounded-lg mb-6 px-4 pt-4" color="accent" flat>
          <p class="primary_text--text text-body-1">
            Para
            <span class="secondary--text">agilizar</span> o processo do seu
            pagamento, anexe o <span class="secondary--text">comprovante</span>.
          </p>

          <v-file-input
            v-model="file"
            accept="image/png, image/jpeg, image/bmp, image/jpg, .pdf"
            placeholder="Anexar comprovante de pagamento"
            outlined
          />
        </v-card>

        <v-btn class="mb-6" color="primary" large block @click="finishHim()">
          Já fiz o pagamento <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>

        <div v-if="jwtDecoded.transaction.redirectURL" class="text-center">
          <a :href="jwtDecoded.transaction.redirectURL" class="text-button">
            Retornar para o site
          </a>
        </div>
      </v-card>
    </v-window-item>

    <loader-hover v-if="loading" />
  </v-window>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { bankSlip, sendReceipt, duplicateBankSlip } from "@/services/payment";
import { getTransaction } from "@/services/checkout";
import { mask } from "vue-the-mask";
import { displayAlert, capitalizeAll } from "@/utils";
import PersonalData from "@/components/checkout/PersonalData";

const axios = require("axios");

export default {
  data() {
    return {
      loading: false,
      valid: false,
      validReceipt: false,
      step: 1,
      form: {
        cep: "",
        uf: "",
        city: "",
        neighboorhood: "",
        address: "",
        number: "",
        complement: "",
      },
      transaction: {},
      file: null,
    };
  },

  components: { PersonalData },

  directives: { mask },

  beforeMount() {
    // prevent access this route with transaction performed
    if (this.transactionPerformed && this.jwtDecoded) {
      const method = this.jwtDecoded.transaction.method;

      if (method != "BOLETO") {
        return this.$router.push({
          path: `/checkout/payment/${method.toLowerCase()}`,
        });
      }
    }

    if (this.current) this.handleCurrent();
  },

  watch: {
    ["form.city"](value) {
      this.form.city = capitalizeAll(value);
    },

    ["form.neighboorhood"](value) {
      this.form.neighboorhood = capitalizeAll(value);
    },

    ["form.address"](value) {
      this.form.address = capitalizeAll(value);
    },

    ["form.complement"](value) {
      this.form.complement = capitalizeAll(value);
    },

    ["form.cep"](value) {
      if (value.length > 8) this.getAddress(value);
    },
  },

  computed: {
    ...mapState([
      "jwtDecoded",
      "current",
      "transactionPerformed",
      "awaitingMethods",
      "lockedMethods",
    ]),

    rule() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setCurrent", "setTransactionPerformed"]),

    // submit bank slip
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        let payload = {
          ...this.form,
          ref: this.jwtDecoded.transaction.ref,
        };

        payload.cep = payload.cep.replace("-", "");

        await bankSlip(payload).then((res) => {
          this.transaction = {
            id: res.body.data.id,
            transactionId: res.body.data.transactionId,
            boletoUrl: "data:application/pdf;base64," + res.body.boletoHTML,
            boletoCode: res.body.data.digitable,
          };

          this.setTransactionPerformed(true);

          // get current transaction to commit
          this.getCurrentTransaction();

          this.step = 2;
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // send receipt to finish
    async finishHim() {
      if (!this.file) {
        // navigation to review
        return this.navigation(
          `/checkout/summary/${this.transaction.transactionId}`
        );
      }

      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", this.file);

        const headers = {
          ref: this.transaction.transactionId,
        };

        await sendReceipt(formData, headers).then(() => {
          this.displayAlert("Comprovante enviado com sucesso");

          // navigation to review
          this.navigation(
            `/checkout/summary/${this.transaction.transactionId}`
          );
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    // get and commit current transaction
    async getCurrentTransaction() {
      try {
        const payload = {
          ref: this.jwtDecoded.transaction.ref,
          document: this.jwtDecoded.customer.document,
        };

        await getTransaction(payload).then((res) => {
          this.setCurrent(res.body); // commit on storage
        });
      } catch (err) {
        console.log(err);
      }
    },

    // handle current transaction
    async handleCurrent() {
      try {
        this.loading = true;

        const payload = this.current.data.transactionId;

        await duplicateBankSlip(payload).then((res) => {
          this.transaction = {
            id: this.current.data.id,
            transactionId: this.current.data.transactionId,
            boletoCode: this.current.data.digitable,
            boletoUrl: res.html,
          };

          this.step = 2;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // request api to get address on input zip code
    async getAddress(cep) {
      try {
        let url = `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`;

        await axios.get(url).then((res) => {
          let temp = JSON.parse(res.request.response);

          this.form.uf = temp.uf;
          this.form.city = temp.localidade;
          this.form.neighboorhood = temp.bairro;
          this.form.address = temp.logradouro;
          this.form.complement = temp.complemento;
        });
      } catch (err) {
        console.log(err);
      }
    },

    async openBankSlip() {
      const win = window.open("", "_blank");

      win.document.write(await this.getHtml());
    },

    getHtml() {
      return ` 
        <html>
          <head>
            <meta charset="utf-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width,initial-scale=1.0">
            <meta name="theme-color" content="#ff5500">
            <title>Boleto Bancário</title>
          </head>

          <body style="margin:0!important">
            <embed width="100%" height="100%" src="data:application/pdf;base64,${this.transaction.boletoUrl}" type="application/pdf" />
          </body>
        </html>
      `;
    },

    // copy bank slip code
    copyCode() {
      var textBox = document.getElementById("code");
      textBox.select();
      document.execCommand("copy");
    },

    // continue current transactions
    continueTransaction() {
      // continue transaction
      const id = this.jwtDecoded.awaitingTransactions.find(
        (e) => e.paymentMethod === "BOLETO"
      ).id;

      this.$root.$emit("continue-transaction", id);
    },

    // router push
    navigation(path) {
      this.$router.push({ path: path });
    },

    displayAlert,
  },
};
</script>

<style lang="scss" scoped>
.centered-input {
  text-align: center;
  width: 100%;
  border-radius: 4px;
}
</style>
